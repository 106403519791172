import barba from "@barba/core"
import prefetch from "@barba/prefetch"
import Alpine from "alpinejs"
import focus from "@alpinejs/focus"
import fitvids from "fitvids"
import { animate, Easing, stagger } from "motion"
import { copyText } from "./lib/clipboard"
import addPanelEvents from "./lib/panel"
import zoom from "./components/lightbox"
import "@appnest/masonry-layout"

Alpine.plugin(focus)
Alpine.store("dialog", "")
Alpine.data("copylink", () => ({
  copied: false,
  timeoutId: null,
  copyToClipboard(text: string) {
    clearTimeout(this.timeoutId)
    copyText(text).then(() => {
      this.copied = true
      this.timeoutId = setTimeout(() => {
        this.copied = false
      }, 2000)
    })
  },
}))
Alpine.data("subscribeForm", () => ({
  posting: false,
  error: false,
  status: "",
  email: "",
  message: "No spam, unsubscribe at any time.",
  submitForm() {
    this.posting = true
    const u = new URL(this.$refs.form.action, window.location.href)
    const formData = new FormData(this.$refs.form)
    fetch(u, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw res.json()
        }
        return res.json()
      })
      .then((res) => {
        if (res.status !== "ok") {
          throw res
        }
        return res
      })
      .then((data) => {
        this.posting = false
        this.status = "success"
        this.message = data?.data?.message || "Success!"
        this.email = ""
      })
      .catch((err) => {
        this.posting = false
        this.status = "error"
        this.message = err?.data?.message || "Something went wrong."
      })
  },
}))
Alpine.magic("pushState", () => {
  return (url: string) => {
    // const next = new URL(window.location.href)
    // next.pathname = url
    barba.history.add(url, "barba")
  }
})
Alpine.data("body", () => ({
  mobileMenuOpen: false,
  mobileMenuScrollHandler: null,
  init() {
    const media = window.matchMedia("(min-width: 640px)")
    const handler = (m: MediaQueryListEvent) => {
      if (m.matches) {
        this.closeMenu()
      }
    }
    try {
      media.addEventListener("change", handler)
    } catch (e) {
      media.addListener(handler)
    }
  },
  openMenu() {
    const height = this.$refs.mobileMenu.getBoundingClientRect().height
    this.mobileMenuOpen = true
    this.mobileMenuScrollHandler = this.handleScroll({
      offset: window.scrollY,
      threshold: 40,
    })
    this.$refs.body.style.transform = `translateY(${height}px)`
    window.addEventListener("scroll", this.mobileMenuScrollHandler, {
      passive: true,
    })
    window.addEventListener("keydown", this.handleKeyDown.bind(this))
  },
  toggleMenu() {
    if (this.mobileMenuOpen) {
      this.closeMenu()
    } else {
      this.openMenu()
    }
  },
  closeMenu() {
    this.mobileMenuOpen = false
    this.$refs.body.style.removeProperty("transform")
    if (this.mobileMenuScrollHandler) {
      window.removeEventListener("scroll", this.mobileMenuScrollHandler)
    }
    window.removeEventListener("keydown", this.handleKeyDown)
  },
  clickOutside() {
    if (this.mobileMenuOpen) {
      this.closeMenu()
    }
  },
  handleKeyDown(e: KeyboardEvent) {
    if (e.key === "Escape") {
      this.closeMenu()
    }
  },
  handleScroll({ offset, threshold }: { offset: number; threshold: number }) {
    return () => {
      const diff = Math.abs(window.scrollY - offset)
      if (diff > threshold) {
        this.closeMenu()
      }
    }
  },
}))

Alpine.start()

let pageVisitTimer: number | undefined = undefined
const onBeforePageVisit = () => {
  clearTimeout(pageVisitTimer)
  document.body.classList.add("disable-lazyload-animation")
}
const onPageVisit = () => {
  zoom.bind("[data-zoomable]")
  fitvids()
  pageVisitTimer = setTimeout(() => {
    document.body.classList.remove("disable-lazyload-animation")
  }, 250)
}

barba.use(prefetch)
barba.hooks.beforeEnter(() => {
  onBeforePageVisit()
})
barba.hooks.afterLeave((data) => {})
barba.hooks.after(() => {
  onPageVisit()
  // TODO: add Google Analytics here
  // https://barba.js.org/docs/advanced/third-party/#analytics-js
})

history.scrollRestoration = "manual"
const scrollPositions: number[] = [0]
const easeOutExpo: Easing = [0.16, 1, 0.3, 1]
const footer = document.querySelector<HTMLElement>("footer[data-footer]")

barba.hooks.before((data) => {
  if (data?.trigger !== "back") {
    scrollPositions.push(barba.history.current.scroll.y)
  }
})
barba.hooks.afterLeave((data) => {
  if (data?.trigger === "back") {
    window.scrollTo({
      left: 0,
      top: scrollPositions.pop() || 0,
      // behavior: "smooth",
    })
  } else {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth",
    })
  }
})

barba.init({
  timeout: 10000,
  // preventRunning: true,
  prevent: ({ el, event }) => {
    if (event.type === undefined) {
      return false
    }
    return isAlpineEventPrevented(el)
  },
  requestError: (trigger, action, url, response) => {
    if (action === "click") {
      // If a click failed to load, just hard navigate to that page.
      barba.go(url, trigger)
      return true
    }
    return false
  },
  transitions: [
    {
      // When transitioning between `news-index` pages, don't animate anything.
      name: "instant",
      from: { namespace: "news-index" },
      to: { namespace: "news-index" },
      enter() {},
    },
    {
      // General purpose transition between pages.
      name: "fade",
      leave(data) {
        return animate(
          getTransitionElements(data.current.container),
          { opacity: 0.0, y: [0, 3] },
          { easing: easeOutExpo, duration: duration(0.2) }
        ).finished
      },
      beforeEnter(data) {
        data.current.container.remove()
      },
      enter(data) {
        // We don't return a promise here to allow the animation to be
        // interrupted by other transitions.
        const els = getTransitionElements(data.next.container)
        animate(
          els,
          { opacity: [0, 1.0], y: [3, 0] },
          {
            easing: easeOutExpo,
            duration: duration(0.6),
            delay: stagger(duration(els.length > 3 ? 0.08 : 0.2)),
          }
        )
      },
      // once(data) {
      //   const els = getTransitionElements(data.next.container)
      //   return animate(
      //     els,
      //     { opacity: [0, 1.0], y: [3, 0] },
      //     {
      //       easing: easeOutExpo,
      //       duration: 1,
      //       delay: stagger(els.length > 3 ? 0.1 : 0.2),
      //     }
      //   ).finished
      // },
    },
    {
      // When transitioning between the same page,
      // don't do anything.
      name: "self",
      enter() {},
    },
  ],
})

document.addEventListener("DOMContentLoaded", () => {
  onPageVisit()
  addPanelEvents()
})

/**
 * getTransitionElements gets all the elements to animate into the page on
 * transition.
 */
function getTransitionElements(parent: Element) {
  let els = Array.from(
    parent.querySelectorAll<HTMLElement>("[data-transition]")
  )
  if (els.length === 0) {
    els = Array.from(parent.querySelectorAll<HTMLElement>("section,article"))
  }
  return footer ? els.concat([footer]) : els
}

const reduceMotion = window.matchMedia(
  "(prefers-reduced-motion: reduce)"
).matches

/**
 * duration is a helper that returns a duration value that respects the
 * user's preference for reduced motion.
 */
function duration(val: number) {
  return reduceMotion ? 0 : val
}

/**
 * isAlpineEventPrevented checks an element for alpinejs attributes which
 * prevent a click event. If so, it returns true.
 */
function isAlpineEventPrevented(el: Element) {
  for (const attr of el.attributes) {
    if (!attr.name.startsWith("@click") && attr.name.startsWith("x-on:click")) {
      continue
    }
    if (!attr.name.includes(".prevent")) {
      continue
    }
    return true
  }
  return false
}
