import zoom from "yet-another-medium-zoom"

zoom.setOptions({
  lightboxGenerator($img, opts, $original) {
    const isMobile = window.innerWidth < 768
    if (isMobile) {
      // Disable album navigation on mobile, since the navigation buttons get
      // hidden and yamz doesn't make it easy to customize.
      // @ts-ignore
      opts.album = undefined
    }
    const $caption = getCaptionElement($original)
    if ($caption) {
      // @ts-ignore
      opts.caption = $caption
    }
    const $lightbox = zoom.defaultLightboxGenerator($img, opts, $original)
    return $lightbox
  },
  scrollAllowance: 45,
})

export default zoom

function getCaptionElement($original: HTMLElement) {
  if (!$original.hasAttribute("data-caption")) {
    return null
  }
  const $grandparent = $original.parentElement?.parentElement
  const $caption = $grandparent?.querySelector("figcaption")
  if (!$caption) {
    return null
  }
  const $captionEl = $caption.cloneNode(true) as HTMLElement
  $captionEl.className = ""
  return $captionEl
}
