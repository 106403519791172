export default function addPanelEvents() {
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Escape" && e.metaKey) {
      const panelURL = getPanelURL(window.location.pathname)
      window.location.assign(panelURL)
    }
  }
  window.addEventListener("keydown", handleKeyDown)
  return () => {
    window.removeEventListener("keydown", handleKeyDown)
  }
}

function getPanelURL(pathname: string) {
  const uid = pathname
    .replace(/^\/|\/$/, "") // remove leading and trailing slashes
    .replace(/\//g, "+") // replace slashes with +
  const dest = uid === "" ? "/panel/site" : `/panel/pages/${uid}`
  return dest
}
