/**
 * copyText copies text to the clipboard, handling cross-browser compatibility
 * issues with different clipboard APIs.
 */
export function copyText(text: string) {
  if (!navigator.clipboard) {
    const el = document.createElement("textarea")
    el.value = text
    el.setAttribute("readonly", "")
    el.className = "absolute opacity-0 pointer-events-none"
    document.body.append(el)

    // Check if text is currently selected
    let selection = document.getSelection()
    const selected =
      selection && selection.rangeCount > 0 ? selection.getRangeAt(0) : false

    el.select()
    document.execCommand("copy")
    el.remove()

    // Restore selection
    if (selected) {
      selection = document.getSelection()
      if (selection) {
        selection.removeAllRanges()
        selection.addRange(selected)
      }
    }

    return Promise.resolve()
  }

  return navigator.clipboard.writeText(text)
}
